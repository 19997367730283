import React from 'react';
import { Typography, Box, Container, Avatar, Link, List, ListItem, ListItemText } from '@mui/material';
import { FormatBold } from '@mui/icons-material';

function about() {
  return (
      <>
          <Box
              sx={{
                  width: '100%',
                  height: 300,
                  position: 'relative',
                  backgroundImage: "url('/FoggyLake.png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
              }}
          >
              <Box
                  sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  }}
              >
                  <Container sx={{
                      width: '100vw',
                  }} >
                      <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', color: 'black', display: 'flex', alignItems: 'center', mb: 4 }}>
                          <Avatar alt="Naveen Ragavanpillai" src="/ProfilePhoto.jpg" sx={{ width: 150, height: 150, mr: 2 }} />
                          <Box>
                              <Typography variant="h4"  gutterBottom>
                                  Naveen Ragavanpillai
                              </Typography>
                              <Typography variant="subtitle1" sx={{ fontWeight: 'Bold' }}>
                                  Solutions Architect / Technical Program Manager
                              </Typography>
                              <Link href="https://www.linkedin.com/in/naveen-ragavanpillai/" target="_blank" rel="noopener noreferrer" sx={{ fontWeight: 'Bold' }}>
                                  LinkedIn
                              </Link>
                          </Box>
                      </Box>

                  </Container>
              </Box>
          </Box>
          <Container
              sx={{
                  height: '100vh', // Adjust this height to match your header height
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  //alignItems: 'center',
                  backgroundImage: 'url(/RobotsBckground5.jpg)',
                  backgroundRepeat: 'repeat',
                  backgroundPosition: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  backgroundBlendMode: 'overlay',
                  //overflow: 'hidden', // Prevent any overflow issues
              }}
              maxWidth={false}
          >
          <Container maxWidth="md" sx={{ mt: 4 }}>
         
              <Box>
                  <Box >
                      <Typography variant="h5" gutterBottom>About Me</Typography>
                      <Typography paragraph>
                          I am a Solutions Architect / Technical Program Manager with over 18 years of experience designing, developing and delivering software solutions with emphasis on modularity, reusability, scalability, reliability and maintainability. Good understanding of cloud architecture, LLMs, retrieval augmented generation, vector search, network, and security architectures. Proficient in problem-solving with a strong aptitude for learning new concepts.
                      </Typography>
                  </Box>
              <Typography variant="h5" gutterBottom>Expertise</Typography>
              <List>
                  <ListItem>
                      <ListItemText primary="Retrieval Augmented Generation, Vector / Semantic search, Azure AI Search, OpenAI Embeddings API, ChatGPT API, AutoGen" />
                  </ListItem>                  
                  <ListItem>
                          <ListItemText primary="Azure Solutions Architecture, pipelines (CI/CD), Git, Team Foundation Server" />
                  </ListItem>
                  <ListItem>
                      <ListItemText primary="C#, .Net, Asp.Net, React, JavaScript, TypeScript, Python, SQL, PowerShell" />
                  </ListItem>                  
                  <ListItem>
                      <ListItemText primary="Enterprise program and transformation management processes design" />
                  </ListItem>
              </List>
          </Box>
          <Box>
              <Typography variant="h5" gutterBottom>Certifications</Typography>
              {/*<Typography component="div" sx={{ ml: 3, lineHeight: 'normal' }}>*/}
              <ListItem>
              <Link href="https://learn.microsoft.com/api/credentials/share/en-us/NaveenR-0267/B3700E7DAF73A85F?sharingId=430C7EB86D797F2B" color="primary">
                      Microsoft Azure Solutions Architect Expert (Exp 2023)
                  </Link>
              </ListItem>
                <ListItem>
                  <Link href="https://coursera.org/share/012b648ec984efb3db122f5dee8055ec" color="primary">
                      Machine Learning by Stanford University on Coursera,
                  </Link>
              </ListItem>
              <ListItem>

                  <Link href="https://coursera.org/share/df24810c6b5157a560b5eb32bf077843" color="primary">
                      Neural Networks and Deep Learning by deeplearning.ai,
                  </Link>
              </ListItem>
              <ListItem>

                  <Link href="https://coursera.org/share/c1f5214a89e2b0efdc30ca023805f3d3" color="primary">
                      Improving Deep Neural Networks: Hyperparameter tuning, Regularization and Optimization,
                  </Link>
              </ListItem>
              <ListItem>

                  <Link href="https://coursera.org/share/14b6f25db9053679430c16d7dd59d0a9" color="primary">
                      Structuring Machine Learning Projects
                  </Link>
              </ListItem>
             {/* </Typography>*/}
            </Box>
              </Container>
</Container>
          </>
  );
}

export default about;