import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, Card, CardContent, Grid, ThemeProvider } from '@mui/material';

function home() {
    return (

        //<ThemeProvider
        //    theme={{palette: {primary: {
        //                    main: '#007FFF', dark: '#0066CC'},
        //    },
        //    }}

        //>
        <>
            <div>
                <Box
                    sx={{
                        width: '100%',
                        height: 300,
                        position: 'relative',
                        backgroundImage: "url('/NYC_BKB_BlackWhite.jpg')",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.35)',
                        }}
                    >
                        <Container sx={{ width: '100vw', padding: 0 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                {/* Header Section */}
                                <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', color: 'black', p: 4, pb: 6 }}>
                                    <Typography variant="h4" gutterBottom sx={{ mt: 0 }}>
                                        Generative AI Tech Demos
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ mt: 0 }}>
                                        The site hosts some tech demos I have built to gain insights into various technology concepts that power them.
                                        You can find a brief introduction to them below. The technical details on the site architecture and how the demos work can be found in the Tech Details page.
                                    </Typography>
                                    {/*<Button variant="contained" sx={{ mt: 2 }}>Available Services</Button>*/}
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Box>

                <Container
                    sx={{
                        height: '100vh', // Adjust this height to match your header height
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        //alignItems: 'center',
                        backgroundImage: 'url(/RobotsBckground1.png)',
                        backgroundRepeat: 'repeat',
                        backgroundPosition: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        backgroundBlendMode: 'overlay',
                        //overflow: 'hidden', // Prevent any overflow issues
                    }}
                    maxWidth={false}
                >
                    <Container maxWidth="md" sx={{ mt: 2 , width: '500 px', padding: 0, margin: 0, bgcolor: 'rgba(255, 255, 255, 0.6)', }} >
                        <Container sx={{
                            bgcolor: 'rgba(255, 255, 255, 0.6)',
                            padding: 2,
                            borderRadius: 1,
                            mb: 2,
                            mt: 4
                        }}>
                        <Box display="flex" alignItems="center" sx={{
                            //bgcolor: 'rgba(255, 255, 255, 0.6)',
                            padding: 2,
                            borderRadius: 1,
                            
                        }}>
                            <Box
                                component="img"
                                    src="/Girl Chats with A Robot 1.png"
                                alt="icon"
                                sx={{
                                    width: 130,
                                    height: 130,
                                    marginRight: 2,
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    backgroundBlendMode: 'overlay',
                                }}
                            />
                            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                Chat With Movie Characters - A Retrieval Augmented Generation Demo
                            </Typography>
                        </Box>
                        <Box sx={{
                            //bgcolor: 'rgba(255, 255, 255, 0.6)',
                            padding: 2,
                            borderRadius: 1,
                            mb: 2,
                        }}>
                            <Typography variant="body1">
                                Navigate to the Chat page and select a movie and a character to chat with that chatacter. This is a demo for retrieval augmented generation (RAG), which allows LLMs to use custom, context specific data to generate content. Each character has its own system message and vector search results from the movies script is provided to GPT-4o API for additional context.
                            </Typography>
                            </Box>
                        </Container>
                        <Container sx={{
                            bgcolor: 'rgba(255, 255, 255, 0.6)',
                            padding: 2,
                            borderRadius: 1,
                            mb: 2,
                            mt: 4
                        }}>
                        <Box display="flex" alignItems="center" sx={{
                            //bgcolor: 'rgba(255, 255, 255, 0.6)',
                            padding: 2,
                            borderRadius: 1,
                            
                        }}>
                            <Box
                                component="img"
                                    src="/RobotOnAComp.png"
                                alt="icon"
                                sx={{
                                    width: 130,
                                    height: 130,
                                    marginRight: 2,
                                }}
                            />
                            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                Vector or Semantic Search Demo
                            </Typography>
                        </Box>
                        <Box sx={{
                            //bgcolor: 'rgba(255, 255, 255, 0.6)',
                            padding: 2,
                            borderRadius: 1,
                        }}>
                            <Typography variant="body1">
                                Unlike keyword search that looks for an exact match, vector search will return content matching the meaning of a query. The app uses movie script extracts, which are stored in Azure AI Search as 1000-character chunks with their corresponding vector embeddings. When a user sends the first message, the app performs a vector search on the movie script to retrieve additional context about the first message from the script, that is then passed to API call.
                            </Typography>
                            </Box>
                        </Container>
                        {/*<Box sx={{*/}
                        {/*    width: 50,*/}
                        {/*    height: 300,*/}
                        {/*    marginRight: 2,*/}
                        {/*}}>*/}
                        {/*</Box>*/}
                    </Container>

                </Container>
            </div>
        </>
        //</ThemeProvider>
    );
}

export default home;