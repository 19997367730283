import React from 'react';
import logo from './logo.svg';
import './App.css';
import ResponsiveAppBar from './components/navBar';
import { DataGrid } from '@mui/x-data-grid';
import ProjectGrid from './components/projectGridGPT';
import { Routes, Route } from 'react-router';
import Switch from '@mui/material/Switch';
import TasksGrid from './components/demoTasksGrid';
import ResourcesGrid from './components/resourceGrid';
import Chat from './components/chat';
import Home from './components/home';
import Tech from './components/tech';
import About from './components/about';
function App() {
    return (
        <div>
            <ResponsiveAppBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                {/*<Route path="/:projectId" element={<TasksGrid />} />*/}
                {/*<Route path="/resources" element={<ResourcesGrid />} />*/}
                <Route path="/chat" element={<Chat />} />
                <Route path="/tech" element={<Tech />} />
                <Route path="/about" element={<About />} />
                {/*<Route path="/projects" element={<ProjectGrid />} />*/}
            </Routes>
        </div>
  );
}

export default App;
