import React, { useState, useEffect } from 'react';
import { MovieCharacterModel, ChatMessage, PromptModel, ResponseModel } from '../models/UIModels';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Chat() {



    const [isLoading_Select, setIsLoading_Select] = useState(true);
    const [isLoading_Message, setIsLoading_Message] = useState(false);

    const [movies, setMovies] = useState<string[]>([]);
    const [movieCharacters, setmovieCharacters] = useState<MovieCharacterModel[]>([]);
    const [selectedCharacter, setSelectedCharacter] = useState('');
    const [selectedCharacterDisplayName, setSelectedCharacterDisplayName] = useState('');


    const [selectedMovie, setSelectedMovie] = useState('');
    const [characters, setCharacters] = useState<MovieCharacterModel[]>([]);


    const [chatHistory, setChatHistory] = useState<string>('');
    const [systemMessage, setSystemMessage] = useState<string>('');
    const [vectorEmbedding, setVectorEmbedding] = useState<string>('');
    const [searchResult, setsearchResult] = useState<string>('');
    const [currentResponse, setCurrentResponse] = useState<string>('');


    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

    const [currentUIMessage, setCurrentUIMessage] = useState('');

    useEffect(() => {

        const fetchData = async () => {
            fetch('https://blinkbox.azurewebsites.net/api/v1/LLMMessaging/GetMovieCharacters').then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }).then(data => {
                setmovieCharacters(data.value);
                setMovies(data.value.map((movie: MovieCharacterModel) => movie.movieName));
                setIsLoading_Select(false);

            }).catch(error => {

                console.error('Error fetching Movies:', error);
                setIsLoading_Select(false);
            });
        }
        // Call the API only on component mount
        if (isLoading_Select) {
            fetchData();
        }

    }, [isLoading_Select]);


    //Not userd and can be removed
    //function getFirstName(fullName: string): string {
    //    let parts = fullName.split(' ');
    //    return parts[0];
    //}



    // Function to handle sending a message
    const sendMessage = async (message: string) => {
        if (!message) return;

        setIsLoading_Message(true);

        const newUserMessage: ChatMessage = { sender: 'user', text: "You : " + message };
        setChatMessages(prevMessages => [...prevMessages, newUserMessage]);

        const promptRequest: PromptModel = { movieName: selectedMovie, characterName: selectedCharacter, userPrompt: message, chatHistory: chatHistory };

        try {
            const response = await fetch(`https://blinkbox.azurewebsites.net/api/v1/LLMMessaging/GetPromptResponse`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(promptRequest),

            })



            response.json().then(dataJson => {

                console.log(dataJson);
                // Update your state with the new data

                if (chatHistory === '') {
                    setSystemMessage(dataJson.systemMessage);
                    setVectorEmbedding(JSON.stringify(dataJson.vectorEmbedding));
                    setsearchResult(dataJson.searchResult);
                    const parts = dataJson.chatHistory.split("A relevant script extract from the movie");
                    setSystemMessage(parts[0]);
                }

                setChatHistory(dataJson.chatHistory);
                setCurrentResponse(dataJson.response);


                // Add bot message here, after the state is updated
                const botMessage: ChatMessage = { sender: 'bot', text: selectedCharacterDisplayName + " : " + dataJson.response };
                setChatMessages(prevMessages => [...prevMessages, botMessage]);
                setIsLoading_Message(false);
            });



        } catch (error) {
            console.error('Error fetching Chat Response:', error);
        }


        setCurrentUIMessage('');
    };

    // Filter out duplicates
    const uniqueOptions = movies.filter(
        (movie, index, self) =>
            index === self.findIndex((t) => t === movie)
    );

    // Handle Enter key in the chat input
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            sendMessage(currentUIMessage);
            //console.log(responseModel.chatHistory);

        }   

    };

    const ClearChatHistory = () => {

        setChatHistory('');
        setCurrentResponse('');
        setSystemMessage('');
        setVectorEmbedding('');
        setsearchResult('');
        setChatMessages([]);

    };

    // Handle change in the movie dropdown
    const handleMovieChange = (event: SelectChangeEvent) => {
        const selected = event.target.value;
        setSelectedMovie(selected);
        ClearChatHistory();
        // Filter characters based on the selected movie
        const filteredCharacters = movieCharacters.filter(movie => movie.movieName === selected);
        setCharacters(filteredCharacters);
    };

    const handleCharacterChange = (e: SelectChangeEvent) => {
        const selected = e.target.value;
        setSelectedCharacter(selected);

        const CharacterDisplayName = movieCharacters.find(c => c.characterName === selected);
               
        setSelectedCharacterDisplayName(CharacterDisplayName?.characterDisplayName || '');

        ClearChatHistory();
    };

    const [sectionVisible, setSectionVisible] = useState(false);
    const [checked, setChecked] = React.useState([true, false]);
    const handleUnderTheHoodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([event.target.checked, event.target.checked]);
        setSectionVisible(!sectionVisible);
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: 300,
                    position: 'relative',
                    backgroundImage: "url('/Mount Rainier.jpg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.35)',
                    }}
                >
                    <Container sx={{ width: '100vw', padding: 0 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            {/* Header Section */}
                            <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', color: 'black', p: 4, pb: 6 }}>
                                <Typography variant="h4" gutterBottom sx={{ mt: 0 }}>
                                    Chat
                                </Typography>
                                <Typography variant="h6" gutterBottom sx={{ mt: 0 }}>
                                    Select a Movie and a Character to Chat
                                </Typography>
                                {/*<Button variant="contained" sx={{ mt: 2 }}>Available Services</Button>*/}
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </Box>
            <Container
                sx={{
                    height: '100vh', // Adjust this height to match your header height
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    //alignItems: 'center',
                    backgroundImage: 'url(/RobotsBckground3.png)',
                    backgroundRepeat: 'repeat',
                    backgroundPosition: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backgroundBlendMode: 'overlay',
                    //overflow: 'hidden', // Prevent any overflow issues
                }}
                maxWidth={false}
            >
                <Container maxWidth="lg" sx={{ mt: 2, width: '600 px', padding: 0, margin: 0, bgcolor: 'rgba(255, 255, 255, 0.6)', }} >

                <div style={{ margin: '20px' }}>
                    <FormControl >
                        <InputLabel style={{ color: 'Black', marginTop: '5px' }} >Select a Movie</InputLabel>
                        <Select onChange={handleMovieChange} value={selectedMovie} labelId="movieLable" label="Select a Movie" style={{ margin: '5px', width: '250px', marginRight: '20px', }}>
                            {uniqueOptions.map((movie, index) => (
                                <MenuItem key={index} value={movie} style={{ marginTop: '5px' }}>
                                    {movie}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        <InputLabel style={{ color: 'Black', marginTop: '5px' }} id="characterLable">Select a Character</InputLabel>
                        <Select onChange={handleCharacterChange} style={{ marginTop: '5px', width: '250px' }} labelId="characterLable" label="Select a Character">
                            {characters.map((character, index) => (
                                <MenuItem key={index} value={character.characterName} style={{ margin: '5px' }}>
                                    {character.characterName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </div>

                <div >
                    <div style={{ height: '400px', overflow: 'auto', border: '1px solid #d3d3d3', marginBottom: '10px' }}>
                        {chatMessages.map((message, index) => (
                            <div key={index} style={{ marginBottom: '10px', marginTop: '10px', textAlign: message.sender === 'user' ? 'right' : 'left' }}>
                                {message.text}
                            </div>
                        ))}
                    </div>
                </div>

                <div >
                    <div>

                        <TextField disabled={isLoading_Message}
                            id="outlined-multiline-flexible"
                            label="Type your message..."
                            value={currentUIMessage}
                            onChange={e => setCurrentUIMessage(e.target.value)}
                            onKeyDown={handleKeyPress}
                            style={{
                                width: '90%',
                                paddingRight: '10px'
                            }}
                        />
                        <Button onClick={() => sendMessage(currentUIMessage)} variant="outlined" style={{
                            marginRight: '10px',
                            height: '100%'
                        }}>Send</Button>
                    </div>
                    <div>

                        <FormControlLabel style={{
                            marginTop: '10px'
                        }} control={<Checkbox onChange={handleUnderTheHoodChange} />} label="Look under the hood" />
                        {sectionVisible && (
                            <div>
                                <Accordion style={{ marginTop: '20px' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography>System Message for the character</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {systemMessage}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <Typography>First Prompt - Vector Embedding from Open AI ada-002</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography style={{ wordWrap: 'break-word' }}>
                                            {vectorEmbedding}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <Typography>Azure AI Search - Vector Search Result</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {searchResult}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4-content"
                                        id="panel4-header"
                                    >
                                        <Typography>Chat History</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {chatHistory}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5-content"
                                        id="panel5-header"
                                    >
                                        <Typography>Current Response</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {currentResponse}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                        )}

                    </div>
                </div>

                </Container>
            </Container>
        </>
    );

}

export default Chat;